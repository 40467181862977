import React from "react";
import PageHeader from '../components/header/PageHeader';

function TermsAndConditionsPage() {
    return (
        <div className="page-content document">
            <PageHeader title={`Terms of Service`} />

            <div className="container">
                <div className="row">
                    <p>Welcome to Zero Bounce Club. These Terms and Conditions govern your access to and use of our email address validation services ("Services"). By accessing or using our Services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you may not access or use our Services.</p>

                    <h2>1. Use of Services</h2>
                    <h3>1.1 Eligibility</h3>
                    <p>You must be at least 18 years old and have the legal capacity to enter into agreements to use our Services. By using our Services, you represent and warrant that you meet these eligibility requirements.</p>

                    <h3>1.2 Compliance</h3>
                    <p>You agree to comply with all applicable laws, regulations, and third-party agreements when using our Services. You are solely responsible for ensuring that your use of the Services does not violate any laws or infringe upon the rights of others.</p>

                    <h2>2. Intellectual Property</h2>
                    <h3>2.1 Ownership</h3>
                    <p>All content, materials, and intellectual property rights associated with our Services, including but not limited to logos, trademarks, and software, are owned or licensed by Zero Bounce Club. You may not use, reproduce, or distribute any of our intellectual property without prior written consent.</p>
                    <h3>2.2 User Content</h3>
                    <p>By using our Services, you may submit or upload content, including but not limited to email addresses and validation results. You retain ownership of any content you submit, but by submitting content to our Services, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content for the purpose of providing and improving our Services.</p>

                    <h2>3. Data Privacy</h2>
                    <h3>3.1 Collection and Use</h3>
                    <p>We collect and use personal information in accordance with our Privacy Policy. By using our Services, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
                    <h3>3.2 Data Security</h3>
                    <p>We take reasonable measures to protect the security of your personal information. However, we cannot guarantee the security of your data, and you acknowledge and agree that you provide your information at your own risk.</p>

                    <h2>4. Limitation of Liability</h2>
                    <h3>4.1 Disclaimer</h3>
                    <p>To the fullest extent permitted by law, Zero Bounce Club disclaims all warranties, express or implied, regarding the Services, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                    <h3>4.2 Limitation of Liability</h3>
                    <p>In no event shall Zero Bounce Club be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Services, whether based on warranty, contract, tort, or any other legal theory, even if Zero Bounce Club has been advised of the possibility of such damages.</p>

                    <h2>5. Indemnification</h2>
                    <p>You agree to indemnify, defend, and hold harmless Zero Bounce Club and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Services or any violation of these Terms and Conditions.</p>

                    <h2>6. Governing Law and Dispute Resolution</h2>
                    <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Delhi, India, without regard to its conflict of law principles. Any dispute arising out of or in connection with these Terms and Conditions shall be resolved exclusively by the courts of Delhi, India.</p>

                    <h2>7. Changes to Terms and Conditions</h2>
                    <p>Zero Bounce Club reserves the right to modify or update these Terms and Conditions at any time without prior notice. Your continued use of the Services after any such changes constitutes acceptance of the modified Terms and Conditions.</p>

                    <h2>8. Payment Terms</h2>
                    <p>Zero Bounce Club uses Razorpay as the payment processor for our Services. By making a payment through our platform, you agree to abide by Razorpay's Terms of Service and Privacy Policy.</p>

                    <h2>9. Cancellation Policy</h2>
                    <p>Once a payment is successfully processed through our platform, it cannot be cancelled or refunded. Please ensure that you review your order carefully before making a payment.</p>

                    <h2>10. Disputes and Chargebacks</h2>
                    <p>If you believe that a payment has been made in error or without authorization, please contact our support team immediately for assistance. Chargebacks and disputes initiated through your payment method provider without prior communication with us may result in additional fees and account suspension.</p>

                    <h2>11. Payment Security</h2>
                    <p>We take the security of your payment information seriously. All payment transactions are encrypted and processed securely through Razorpay's payment gateway. However, Zero Bounce Club is not responsible for any unauthorized access to or misuse of your payment information.</p>

                    <h2>12. Shipping & Delivery Policy</h2>
                    <p>Shipping is not applicable for business.</p>

                    <h2>13. Disclaimer</h2>
                    <p>Zero Bounce Club reserves the right to modify, update, or discontinue any payment options or policies at any time without prior notice.</p>

                    <h2>14. Contact Us</h2>
                    <p>If you have any questions or concerns about these Terms and Conditions, please contact us at support@zerobounceclub.com.</p>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage;