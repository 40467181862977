import React, { useState } from "react";
import cogoToast from 'cogo-toast'

function AccountDeletionRequestPage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    })
    const [submitting, setSubmitting] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) return

        setSubmitting(true);
        console.log(formData);

        try {
            const response = await fetch('https://zerobounceclub.net/api/cs/requests/delete-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json()

            console.log("Form submit response", responseData)
            if (responseData.status == 1) {
                setFormData({
                    name: '',
                    email: '',
                    reason: '',
                    message: ''
                });
                cogoToast.success(responseData.message)
            } else {
                cogoToast.error(responseData.message)
            }
        } catch (error) {
            console.log(error)
            cogoToast.error("Something went wrong, please try after some time!")
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="page-content contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center">
                        <h2 className="section-header">Account Deletion Request Form</h2>
                        <p className="mb-5">Please fill the required details to generate the account deletion request</p>
                        <form id="contact-form" onSubmit={handleSubmit}>
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Your Name*"
                                        required={true}
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        onInvalid={(e) => e.target.setCustomValidity('Please enter your full name')}
                                        onInput={(e) => e.target.setCustomValidity('')} />
                                </div>

                                <div className="col-md-6 ">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Your Email*"
                                        required={true}
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        onInvalid={(e) => e.target.setCustomValidity('Please enter your email')}
                                        onInput={(e) => e.target.setCustomValidity('')} />
                                </div>

                                <div className="col-md-12">
                                    <select
                                    style={{color: !!formData.reason ? '#222' : '#666'}}
                                        name="reason"
                                        required={true}
                                        value={formData.reason}
                                        onChange={handleInputChange}
                                        onInvalid={(e) => e.target.setCustomValidity('Please select a reason')}
                                        onInput={(e) => e.target.setCustomValidity('')}>
                                        <option value={""}>Select a reason*</option>
                                        <option value={'services_no_longer_needed'}>Services no longer needed</option>
                                        <option value={'validation_results_quality_issue'}>Validation results quality issue</option>
                                        <option value={'found_cheaper_services_anywhere'}>Found cheaper services anywhere</option>
                                    </select>
                                </div>

                                <div className="col-md-12">
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        rows="6"
                                        placeholder="Message (optional)"
                                        value={formData.message}
                                        onChange={handleInputChange}></textarea>
                                </div>

                                <div className="col-md-12 text-center">
                                    {submitting && <div className="loading">Submitting your deletion request...</div>}
                                    <button type="submit">Send Account Deletion Request</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AccountDeletionRequestPage;