import React from "react";
import PageHeader from '../components/header/PageHeader';
import AboutBannerImage from '../../assets/img/about/about-banner.jpg'
import OurMissionArticle from "../components/articles/OurMissionArticle";
import HowItWorksArticle from "../components/articles/HowItWorksArticle";

function AboutPage() {
    return (
        <div className="page-content">
            <PageHeader title="Our Services" image={AboutBannerImage} />

            <div className="container">
                <div className="row">
                    <div className="col-md-7 about-content">
                        <h2 className='section-header'>About Zero Bounce Club</h2>
                        <p>Welcome to Zero Bounce Club, your trusted partner in email validation and deliverability solutions. Our mission is to help businesses of all sizes maintain clean, accurate email lists, improve their email deliverability, and protect their sender reputation.</p>
                        <h2 className='section-header'>Our Story</h2>
                        <p>Founded with a passion for improving email communications, Zero Bounce Club has grown into a leading provider of email validation services. Our journey began with a simple goal: to create a reliable, efficient solution for businesses struggling with email bounce rates and poor deliverability. Today, we serve a diverse range of clients, from small startups to large enterprises, helping them achieve their email marketing goals with confidence.</p>
                        
                        <h2 className="section-header">What We Do?</h2>
                        <h3>Email Validation</h3>
                        <p>Our core service is email validation, which involves verifying the validity and deliverability of email addresses. This process helps businesses maintain clean email lists, reduce bounce rates, and improve their email campaign performance. Our email validation services include:</p>
                        <ul className="key-list">
                            <li><span className="highlighted">Syntax and Formatting Check:</span> Ensures email addresses conform to standard email formatting rules.</li>
                            <li><span className="highlighted">Domain Validation:</span> Checks if the domain is valid and active.</li>
                            <li><span className="highlighted">MX Record Check:</span> Verifies the existence of mail exchange records for the domain.</li>
                            <li><span className="highlighted">SMTP Authentication:</span> Communicates with the mail server to ensure the mailbox exists.</li>
                            <li><span className="highlighted">Disposable Email Detection:</span> Detects temporary or disposable email addresses.</li>
                            <li><span className="highlighted">Emails Validation Report</span> Report detailing the results of the email validation process.</li>
                        </ul>
                        <h3>Flexible Pricing Plans</h3>
                        <p>We understand that businesses have different needs and budgets, which is why we offer flexible pricing options:</p>
                        <ul>
                            <li><span className="highlighted">Free Plan:</span> 100 free credits every month to get you started with our services.</li>
                            <li><span className="highlighted">Pay-as-You-Go:</span> Purchase credits as needed, with volume discounts for larger purchases.</li>
                        </ul>

                        <h2 className="section-header">Our Target Market</h2>
                        <p>Our services are designed to benefit a wide range of industries and use cases, including:</p>
                        <ul className="key-list">
                            <li><span className="highlighted">Email Marketers:</span> Improve the deliverability and engagement rates of email marketing campaigns.</li>
                            <li><span className="highlighted">eCommerce and Retail:</span> Validate customer emails during account creation or checkout to reduce fraud and ensure reliable communication.</li>
                            <li><span className="highlighted">CRM Systems:</span> Ensure accurate email data for customer communication and marketing.</li>
                            <li><span className="highlighted">SaaS Providers:</span> Validate user emails during sign-up or communication to ensure they reach their users.</li>
                        </ul>

                        <h2 className="section-header">Our Values</h2>
                        <h3>Accuracy and Reliability</h3>
                        <p>We are committed to providing the most accurate and reliable email validation services. Our advanced algorithms and thorough validation processes ensure that our clients can trust the results they receive.</p>
                        <h3>Customer Focus</h3>
                        <p>Our clients are at the heart of everything we do. We are dedicated to providing exceptional customer support and continuously improving our services based on client feedback and industry advancements.

</p>
                        <h3>Innovation</h3>
                        <p>We embrace innovation and are constantly exploring new ways to enhance our services and stay ahead of the curve in the email validation industry.</p>
                        <h3>Integrity</h3>
                        <p>We operate with the highest level of integrity, ensuring transparency and honesty in all our interactions. We are committed to protecting our clients' data and maintaining their trust.</p>
                        <h3>Meet Our Team</h3>
                        <p>Our team is composed of experienced professionals passionate about email technology and customer success. We bring a wealth of knowledge and expertise to the table, working tirelessly to ensure our clients receive the best possible service.</p>

                    </div>
                    <div className="col-md-5">
                        <OurMissionArticle />
                        <HowItWorksArticle />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;