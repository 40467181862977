import React from "react";
import PageHeader from '../components/header/PageHeader';

function PrivacyPolicyPage() {
    return (
        <div className="page-content document">
            <PageHeader title="Privacy Policy" />

            <div className="container">
                <div className="row">
                    <p>This Privacy Policy describes how <a href="/">ZeroBounceClub</a> ("we", "us", or "our") collects, uses, and protects the personal information you provide when using our email address validation services ("Services").</p>

                    <h2>1. Information We Collect</h2>
                    <p>When you use our Services, we may collect the following types of information:</p>
                    <h3>Personal Information</h3>
                    <p>When you use our email address validator, you may provide us with personal information such as your email address. We only collect personal information necessary to provide and improve our Services.</p>
                    <h3>Usage Information</h3>
                    <p>We may collect information about how you use our Services, including your interactions with the validator, the results of validations, and technical data such as IP addresses, device information, and browser type.</p>

                    <h2>2. How We Use Your Information</h2>
                    <p>We use the information we collect for the following purposes:</p>
                    <h3>Providing Services</h3>
                    <p>We use your personal information to validate email addresses and deliver the results to you.</p>
                    <h3>Improving Services</h3>
                    <p>We may use usage information to analyze trends, troubleshoot issues, and improve the performance and functionality of our Services.</p>
                    <h3>Communications</h3>
                    <p>We may use your email address to communicate with you about your use of the Services, updates, and other relevant information.</p>

                    <h2>3. Data Security</h2>
                    <p>We are committed to protecting the security of your personal information. We implement appropriate technical and organizational measures to safeguard your data from unauthorized access, disclosure, alteration, and destruction.</p>

                    <h2>4. Data Retention</h2>
                    <p>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                    <h2>5. Third-Party Services</h2>
                    <p>We may use third-party services to assist us in providing and improving our Services. These third parties may have access to your personal information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                    <h2>6. Data Deletion</h2>
                    <p>You have the right to request the deletion of your personal information from our systems. If you would like us to delete your data, please submit a request through our <a href="/account-deletion">Data Deletion Request Form</a>. Upon receiving your request, we will promptly delete your personal information from our records, unless retention is required by law or necessary for legitimate business purposes.</p>

                    <h2>7. Changes to This Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

                    <h2>8. Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at support@zerobounceclub.net.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage;