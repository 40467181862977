import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos'

import './App.scss';

import Layout from '../app/layout/MainLayout';
import Home from '../app/main';
import Services from '../app/services';
import About from '../app/about';
import Contact from '../app/contact';
import Pricing from '../app/pricing';
import AccountDeletionRequest from './account-deletion';
import PrivacyPolicyPage from './privacy-policy';
import TermsAndConditionsPage from './terms-and-conditions';

AOS.init()

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/account-deletion" element={<AccountDeletionRequest />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
