import React from 'react';

const Logo = ({ size }) => (
    <div className={`brand-logo ${size ?? ''}`}>
        <div className='logo-image'>
            <LogoImage />
        </div>
        <div className='logo-text'>
            <div className='app-title'>Zero Bounce Club</div>
            <div className='app-slogan'>Ensuring Trusted Email Delivery</div>
        </div>
    </div>
)

const LogoImage = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 516.5 398.3">
        <g>
            <path fill="#CCCCCC" d="M435.4,156.2l12.9-0.3c0,0,13.4-1.5,18.9,5.3l30.7-27.6c-10.5-13.4-21-13.4-27.6-13.3l-54.6,0.1" />
            <path fill="#CCCCCC" d="M467.2,161.2l30.6-27.6c10.9,11.7,10.8,27.5,10.8,27.5c0.4,186.4,0.4,171.6,0.4,187.9c-0.1,5.6,0,17.6-12,29.2
		l-30-32.3c2.5-3,2.9-6.4,2.9-6.4l-0.4-171.1C469.6,165.3,468.6,163,467.2,161.2L467.2,161.2z"/>
            <path fill="#CCCCCC" d="M272.1,352.5l178.8-0.4c8.8,0,13.5-3.1,16.1-6.1l30.1,32.3c-11.7,10-23.9,10-23.9,10L202,389" />
        </g>
        <g>

            <path fill="#FFE000" d="M346,61.2c1-2.5,1.6-5.7,1.6-5.7s0.3-1.6,0.3-3.2c0-1.7-1.6-5.3-1.6-5.3s-10.6-19.2-10.4-19.6
		c-15.5-29.9-44.5-14.5-44.5-14.5l-265.1,141c-5.6,3-14.6,7.8-17.4,24.1l38.9,9.7c1.6-8.4,13.7-13.2,13.7-13.2L315.8,39.2
		c10.4-5.5,15.6,2.6,15.6,2.6l5.2,9.8l0,0c0.5,0.9,0.4,2.1,0,3.3l0,0c0,0-0.4,1.5-1.1,2.8"/>
            <path fill="#11715B" d="M346,61.2c-4.1,6.4-122.7,192.4-122.7,192.4s-8.6,12.1-18.3,6.4c-7.3-4.2-129-72.8-129-72.8S66,176.6,86,182.7
		c24,7.3,112.8,32.9,112.8,32.9L332.1,61.8c0,0,1.9-2,3.3-4.3L346,61.2z"/>
            <path fill="#FFB53E" d="M47.7,187.6c-0.4,2.2-0.1,4.6,1.3,7.3l78.4,147.4c0,0,1.9,2.8,5.4,4.2L122,388c-15.6-4.5-21-14.9-23.7-19.6
		c-7.5-14.1-0.7-1.3-86.1-161.9c0,0-7.3-13.6-3.4-28.6L47.7,187.6L47.7,187.6z"/>
            <path fill="#FF7640" d="M429.2,222.3c-2.4,14.7-12.1,19.8-18.6,23.3L147.1,385.7c0,0-10.5,5.6-25.1,2.3l10.8-41.5
		c3.6,1.4,9,1.9,16.5-2.1l234.3-124.6c0,0,4.5-2.8,5.5-7.3L429.2,222.3z"/>
            <path fill="#F94B4B" d="M429.2,222.3c2.7-14.6-6.1-32-6.1-32L362.2,76l-23.3,37.9c0,0,44.9,84.5,49.1,92.3c1.1,2.2,1.5,4.5,1.1,6.3
		L429.2,222.3z"/>
            <path fill="#CCCCCC" d="M134.7,318.3l32-67.2l18.1,10.5l-42.2,57.6C142.6,319.1,133,330.9,134.7,318.3z" />
            <path fill="#CCCCCC" d="M360.5,206.6l-88.8-8l-15.6,24.2l102.1-8.6C358.3,214.1,374.9,211,360.5,206.6z" />
        </g>
    </svg>

)

export default Logo