import React, { Component } from "react";
import EmailValidationStatusModal from "./EmailValidationStatusModal";
import SweetAlert from 'sweetalert2-react';
import cogoToast from "cogo-toast";

const defaultEmailStatus = {
    status: 'unknown',
    message: 'Unknown',
    mx_found: false
}

export class EmailValidatorBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            inputEmail: '',
            validationStatus: null,
            showEmailStatusAlert: false,
            showCreditsBalanceAlert: false
        }
    }

    render() {
        const { inputEmail, showEmailStatusAlert, validationStatus, showCreditsBalanceAlert} = this.state

        return (
            <>
                <div id="email-validator-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2>Try our Email Validator</h2>
                            </div>
                            <div className="col-lg-8 input-field">
                                <input placeholder="Enter an email address"
                                    value={inputEmail}
                                    onChange={e => this.setState({ inputEmail: e.target.value })} />
                                <button type="submit" onClick={this.validateEmail}>Verify</button>
                            </div>
                        </div>
                    </div>
                </div>
                <EmailValidationStatusModal
                    show={showEmailStatusAlert}
                    data={validationStatus}
                    onClose={() => this.setState({ showEmailStatusAlert: false, validationStatus: null, inputEmail: '' })} />
                <SweetAlert
                    show={showCreditsBalanceAlert}
                    title="Credits Balance"
                    text={`You only allowed 5 credits to test our email validator service \nUnlock the power of our email validator with 100 Free verification credits! Sign up for a free account now`}
                    confirmButtonText={'Signup Now'}
                    showCancelButton={true}
                    onConfirm={() => this.setState({ showCreditsBalanceAlert: false }, () => window.location.href = "https://app.zerobounceclub.net/signup")}
                    onCancel={() => this.setState({ showCreditsBalanceAlert: false })}
                />
            </>

        )
    }

    validateEmail = async () => {
        const { inputEmail } = this.state

        if (!!inputEmail) {
            this.setState({ showEmailStatusAlert: true})
            try {
                const response = await fetch('https://zerobounceclub.net/api/cs/validate/email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email: inputEmail })
                });

                const responseData = await response.json()

                console.log("Api response", responseData)
                if (responseData.status == 1) {
                    this.setState({validationStatus: responseData.data })
                } else if (responseData.status == -1) {
                    this.setState({ showEmailStatusAlert: false, showCreditsBalanceAlert: true})
                } else {
                    this.setState({showEmailStatusAlert: false}, () => cogoToast.error(responseData.message))
                }
            } catch (error) {
                console.log(error)
                this.setState({showEmailStatusAlert: false}, () => cogoToast.error("Something went wrong, please try after some time!"))
            }
        }
    }


}